<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { Api } from "../../../helpers";
import { Erreur } from "../../../helpers/error";
import Swal from "sweetalert2";
import loaderProcessVue from "../../../components/widgets/loaderProcess.vue";
import candidateCard from "../../../components/widgets/card/candidateCard.vue";
// import chartCandidatAwardVue from "../../../components/widgets/chartComponent/chartCandidatAward.vue";
import statAward from "../../../components/widgets/statAward.vue";
/**
 * Projects-overview component
 */
export default {
  page: {
    title: "Projects Overview",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    loaderProcessVue,
    candidateCard,
    // chartCandidatAwardVue,
    statAward,
  },
  data() {
    return {
      titre: "",
      statutAward: "",
      graphSerie: null,
      awardDate: "",
      progress: "",
      process: false,
      statUser: "",
      awardDetails: [],
      callCahrt: false,
      serie: [],
      chartTitle: "Evolution des votes",
      searchQuery: "",
      categorieChart: [],
      serieData: [],
      image: "",
      candidates: [],
      updateModal: false,
      addModalCandidate: false,
      addModal: false,
      time: "",
      awardCategorie: {
        name: "",
        votePrice: "",
        description: "",
        voteCount: 0,
      },
      candidateUser: {
        name: "",
        description: "",
        idCategorie: [],
      },
      title: "Details de la compétition",
      items: [],
    };
  },
  methods: {
    showAddModal() {
      this.addModal = true;
    },

    showUpdateModal() {
      this.updateModal = true;
    },

    showAddModalCandidate() {
      this.addModalCandidate = true;
    },

    imageFileSelected(event) {
      this.imageSelectFile = event.target.files[0];
      console.log(this.imageSelectFile);
    },

    imagePFileSelected(event) {
      this.imagePSelectFile = event.target.files[0];
      console.log(this.imagePSelectFile);
    },

    videoSelect(event) {
      this.video = event.target.files[0];
      console.log(this.video);
    },

    imgPortraitCandidate(event) {
      this.candidateImgPortrait = event.target.files[0]; // Get the selected image or video
      console.log(this.imgSelect);
    },

    imgPaysageCandidate(event) {
      this.candidateImgPaysage = event.target.files[0];
      console.log(this.imgPaysage);
    },

    createCandidate() {
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      this.process = true;
      this.addModalCandidate = false;

      this.titre = "Création d'un candidat";
      Api.postFormData(
        `/voting/rest/candidat/create-candidat?categories=${this.$route.params.id}&description=${this.candidateUser.description}&name=${this.candidateUser.name}`,
        {
          imagepaysage: this.candidateImgPaysage,
          imageportrait: this.candidateImgPortrait,
        },
        onUploadProgress
      )
        .then((response) => {
          this.process = false;
          Swal.fire("Succes!", "Candidat créé avec succès", "success");
          console.log(response);
          location.reload();
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },

    updateCategorie() {
      this.process = true;
      this.updateModal = false;
      this.titre = "Modification de la catégorie";
      const onUploadProgress = (progressEvent) => {
        this.progress = Math.round(
          (progressEvent.loaded / progressEvent.total) * 100
        );
        console.log(`Upload progress: ${this.progress}%`);
      };

      if (this.imageSelectFile) {
        this.process = true;
        this.updateModal = false;
        Api.putFormData(
          `/voting/rest/categorie/img-portrait/${this.$route.params.id}`,
          {
            imageportrait: this.imageSelectFile,
          },
          { onUploadProgress }
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Catégorie Modifiée avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      if (this.imagePSelectFile) {
        Api.putFormData(
          `/voting/rest/categorie/img-paysage/${this.$route.params.id}`,
          {
            imagepaysage: this.imagePSelectFile,
          },
          { onUploadProgress }
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Catégorie Modifiée avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }

      if (this.video) {
        this.process = true;
        this.updateModal = false;
        Api.putFormData(
          `/voting/rest/categorie/video-couverture/${this.$route.params.id}`,
          {
            videocouverture: this.video,
          },
          { onUploadProgress }
        )
          .then((response) => {
            this.process = false;
            Swal.fire("Succes!", "Catégorie Modifiée avec succès", "success");
            console.log(response);
            // location.reload()
          })
          .catch((error) => {
            this.process = false;
            console.log(error);
            Erreur.gestionErreur(error.message);
          });
      }
    },

    getDataGraphic() {
      Api.get(
        `/voting/rest/vote/all-vote-categorie-date/${new Date(
          this.$route.params.id
        )}?dateDebut=${this.awardDate.startDate}&dateFin=${new Date()}`
      )
        .then((res) => {
          this.process = false;
          this.statUser = res.data.content;
          console.log(this.statUser);
        })
        .catch((error) => {
          this.process = false;
          console.log(error);
          Erreur.gestionErreur(error.message);
        });
    },
  },
  mounted() {
    // this.process = true
    this.titre = "Chargement de la catégorie";
    const onUploadProgress = (progressEvent) => {
      this.progress = Math.round(
        (progressEvent.loaded / progressEvent.total) * 100
      );
      console.log(`Upload progress: ${this.progress}%`);
    };

    Api.get(`/voting/rest/categorie/${this.$route.params.id}`, {
      onUploadProgress,
    })
      .then((res) => {
        this.process = false;
        this.categorie = res.data.content;

        console.log(this.categorie);
        this.image = this.categorie.imagesMap.imagepaysage.url;
        this.awardCategorie.name = this.categorie.name;
        this.awardCategorie.description = this.categorie.description;
        this.awardCategorie.votePrice = this.categorie.vote_price;
        this.$refs.videoPlayer.src = this.categorie.videoCouverture.url;
        this.statutAward = this.categorie.award.isPublished;
        this.awardDate = this.categorie.award;

        this.items.push(
          {
            text: "Awards",
            href: "/award/awardList",
          },
          {
            text: this.categorie.award.name,
            href: "/award/awardDetail/" + this.categorie.award.id,
          },
          {
            text: this.categorie.name,
            active: true,
          }
        );
        // this.getDataGraphic()
      })
      .catch((error) => {
        this.process = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });

    Api.get(`/voting/rest/candidat/categorie/${this.$route.params.id}`, {
      onUploadProgress,
    })
      .then((res) => {
        this.process = false;
        const data = res.data.content;
        data.forEach((items) => {
          let voteCount = []
          items.votes.forEach((item) => {
            if (item.categorie_id == this.$route.params.id) {
              voteCount.push(item)
            }
          })
          this.candidates.push({
            candidate: items,
            voteUser: voteCount
          })
          this.awardCategorie.voteCount += voteCount.length;
        });
        console.log("liste des candidats")
        this.candidates.sort((a, b) => b.voteUser.length - a.voteUser.length);
        console.log(this.candidates)

        // this.callCahrt = true;
      })
      .catch((error) => {
        this.process = false;
        console.log(error);
        Erreur.gestionErreur(error.message);
      });
  },

  computed: {
    // Calcul de la liste des articles filtrés en fonction de la recherche
    filteredCandidatList() {
      if (!this.searchQuery) {
        return this.candidates;
      }
      return this.candidates.filter((candidat) => {
        const fullName = candidat.candidate.name;
        return fullName.toLowerCase().includes(this.searchQuery.toLowerCase());
      });
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <loaderProcessVue
      :title="titre"
      :progress="progress"
      :visible="process"
    ></loaderProcessVue>

    <div class="card" style="padding: 20px; border-radius: 7px">
      <div class="row">
        <div class="col-xl-6">
          <div class="product-detai-imgs" style="">
            <div
              style="
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                overflow: hidden;
              "
              itemscope
              itemtype="https://schema.org/VideoObject"
            >
              <video
                ref="videoPlayer"
                src=""
                controls
                autoplay
                style="
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  left: 0px;
                  top: 0px;
                  overflow: hidden;
                "
                width="720"
                height="480"
              ></video>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-sm-12 border-1">
          <div class="mt-1 card p-2 shadow-none border">
            <div style="text-align: justify">
              <div
                class="mb-2"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                "
              >
                <div style="width: 80%">
                  <h2>{{ awardCategorie.name }}</h2>
                </div>
                <div>
                  <button
                    type="submit"
                    @click="showUpdateModal"
                    class="btn border"
                  >
                    <span style="color: #07693a"
                      ><i class="mdi mdi-pencil font-size-18"></i
                    ></span>
                  </button>
                </div>
              </div>
              <div>{{ awardCategorie.description }}</div>
            </div>
          </div>

          <div class="row">
            <statAward
              :title="'Nommés'"
              :icon="'mdi mdi-account'"
              :value="candidates.length"
            ></statAward>
            <statAward
              :title="'Votes'"
              :icon="'mdi mdi-hand'"
              :value="awardCategorie.voteCount"
            ></statAward>
            <statAward
              :title="'Rang'"
              :icon="'mdi mdi-equalizer'"
              :value="this.$route.params.rang"
            ></statAward>
          </div>
        </div>
      </div>
    </div>

    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <div></div>
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
        "
      >
        <div style="max-width: 300px; margin-right: 10px">
          <div class="input-group">
            <input
              type="text"
              class="form-control"
              placeholder="Rechercher un candidat"
              style="border-radius: 8px"
              v-model="searchQuery"
            />
          </div>
        </div>
        <button
          v-if="statutAward == false"
          type="submit"
          @click="showAddModalCandidate"
          class="btn c2play-primary"
          style="margin-right: 10px"
        >
          <span style="color: #fff"
            ><i class="fas fa-plus font-size-18"></i> Ajouter un nommé
          </span>
        </button>
      </div>
    </div>

    <br />
    <div class="row">
      <candidateCard
        v-for="candidate in filteredCandidatList"
        :key="candidate.id"
        :rang="filteredCandidatList.indexOf(candidate) + 1"
        :statutAward="statutAward"
        :candidate="candidate"
      >
      </candidateCard>
    </div>

    <!-- <chartCandidatAwardVue :serie="serie" :categorie="categorieChart" :title="chartTitle" v-if="callCahrt == true">
        </chartCandidatAwardVue> -->

    <b-modal
      v-model="updateModal"
      id="modal-xl"
      size="xl"
      title="Nouvelle catégorie"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="form-group col-lg-6 mb-3">
              <label for="projectname" class="col-form-label">Nom</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="awardCategorie.name"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le nom de la catégorie"
                />
              </div>
            </div>

            <div class="form-group col-lg-6 mb-3">
              <label class="col-form-label">Prix du vote (XAF)</label>
              <div class="col-lg-12">
                <input
                  id="projectname"
                  v-model="awardCategorie.votePrice"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le prix du vote"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image Portrait</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imageFileSelected($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Image plateau (Paysage)</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imagePFileSelected($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-4">
              <label for="projectbudget" class="col-form-label"
                >Vidéo de couverture</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="videoSelect($event)"
                  class="form-control"
                  accept=".MKV, .AVI, .MP4, MOV"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                v-model="awardCategorie.description"
                rows="3"
                :placeholder="
                  $t('pages.programme.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>
        <!-- <div class="row mb-4">
              <label class="col-form-label col-lg-2">Vidéo</label>
              <div class="col-lg-10">
                <DropZone @drop.prevent="drop" @change="selectedFile" />
                <span class="file-info">Fichier: {{ dropzoneFile.name }}</span>
              </div>
            </div> -->
        <!-- <h4>{{ tags }}</h4>
            <h4>{{ categories }}</h4> -->
        <div class="row justify-content-center">
          <div
            style="
              margin-top: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            "
          >
            <button
              type="submit"
              class="btn c2play-primary"
              @click="updateCategorie"
            >
              {{ $t("updateButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal
      v-model="addModalCandidate"
      id="modal-xl"
      size="xl"
      title="Nouveau candidat"
      title-class="font-18"
      hide-footer
    >
      <div class="card-body">
        <b-alert
          v-model="isAuthError"
          variant="danger"
          class="mt-1"
          dismissible
        >
          {{ authError }}
        </b-alert>
        <form>
          <div class="row">
            <div class="form-group col-lg-12 mb-3">
              <label for="projectname" class="col-form-label"
                >Nom du candidat</label
              >
              <div class="col-lg-12">
                <input
                  id="projectname"
                  name="projectname"
                  type="text"
                  class="form-control"
                  placeholder="Entrez le nom du candidat"
                  v-model="candidateUser.name"
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="form-group col-lg-6">
              <label for="projectbudget" class="col-form-label"
                >Image Portrait</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imgPortraitCandidate($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>

            <div class="form-group col-lg-6">
              <label for="projectbudget" class="col-form-label"
                >Image plateau (Paysage)</label
              >
              <div class="col-lg-12">
                <input
                  id="projectbudget"
                  name="projectbudget"
                  type="file"
                  @change="imgPaysageCandidate($event)"
                  class="form-control"
                  accept=".png, .jpeg, .jpg"
                />
              </div>
            </div>
          </div>

          <div class="form-group row mb-4">
            <label for="projectbudget" class="col-form-label"
              >Description</label
            >

            <div class="col-lg-12">
              <textarea
                id="projectdesc"
                class="form-control"
                rows="3"
                v-model="candidateUser.description"
                :placeholder="
                  $t('pages.programme.formulaire.description.placeholder')
                "
              ></textarea>
            </div>
          </div>
        </form>
        <div class="row justify-content-end">
          <div class="col-lg-10" style="margin-top: 10px">
            <button
              type="submit"
              class="btn c2play-primary"
              style="position: relative; left: 40%"
              @click="createCandidate"
            >
              {{ $t("addButton") }}
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </Layout>
</template>
